.navbar {
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  border-bottom: 1px solid transparent;

  &.bg-primary {
    border-color: darken($primary, 5%);
  }

  &.transparent {
    border-color: $white;
    background-color: transparent !important;
  }

  .nav-item {
    &.icon {
      font-size: 1.5rem;

      @include media-breakpoint-down(md) {
        &:first-child {
          .nav-link {
            margin-left: 0;
          }
        }
      }

      .nav-link {
        margin-left: 1rem;

        >i {
          display: block;
        }
      }
    }
  }
}

@mixin bg($width, $height) {
  @media (max-width: $width) and (max-height: $height) {
    @content;
  }
}

.landing-page {
  background-image: url('/images/ebba_brahe.jpg');
  background-color: $primary;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  @include bg(1124px, 843px) {
    background-image: url('/images/ebba_brahe_small.jpg');
  }

  &.cover {
    min-height: 100vh;
  }

  &.background-attachment-initial {
    background-attachment: initial;
  }
}

.header-content-bg {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-top: $bs-navbar-height;

  .landing-page.cover & {
    background-color: rgba(0, 0, 0, 0.3);
    margin-top: 100px;
    padding-top: 0;
  }
}

.website-title {
  font-size: 6vw;
  line-height: 1;

  @include media-breakpoint-up(lg) {
    font-size: 4rem;
  }
}

.header-hr {
  width: 80%;
}

.subline {
  font-size: 1.2rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.4rem;
  }
}
