main {
	margin-top: 0;
}

#kontakt {
	a.text-light {
		margin-left: $font-size-base + 0.5rem;
	}

	.fa-solid {
		position: absolute;
		top: 0;
		left: -$font-size-base - 0.5rem;
		line-height: $line-height-base;
	}
}
