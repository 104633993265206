@import 'fontawesome';
@import 'bootstrap';
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

$bs-navbar-height: 57px;

body {
	min-height: 100vh;
}

@import 'header';
@import 'main';

.home {
	@import 'home';
}
